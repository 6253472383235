'use client';

import { createContext, useContext, useState } from 'react';
import { generateLabTest } from '@/services/utils/generators';

import { Test } from '@/services/types/lab-test/tests';
import { LabTestContext as LabTestContexts, Open, SelectedTests, ErrorTag } from '@/services/types/lab-test';

import type { ReactNode } from 'react';
import type { Vendor } from '@/services/types/lab-test/cart';

import Drawer from '../molecules/drawer';
import LoginCompo from '@/app/login/login';
import NaModal from '../molecules/na_modal';
import Modal from '@/components/molecules/modal';
import SortByModal from '../molecules/sort-by-modal';
import MoreTestList from '../molecules/more-test-list';
import FilterByModal from '../molecules/filter-by-modal';
import LabTestCategoryContextProvider from './lab-test-category-context';
import LabTestSearchContextProvider from '@/lab-test/context/lab-test-search-context';
import LabCartProvider from './lab-cart-context';
import BookTestDrawer from '../molecules/book-test-drawer';
import VendorsModal from '../organisms/vendors-modal';
import LabModal from '../molecules/lab-modal';
import SwitchVendorModal from '../molecules/switch-vendor-modal';
import BookModal from '../molecules/book-modal';
import PatientList from '../molecules/patient-list';

const LabTestContext = createContext<LabTestContexts>({
    testId: '',
    moreTests: [],
    isOpen: false,
    loading: false,
    open: Open.NONE,
    orderData: null,
    setOrderData: () => {},
    selectedTests: {},
    setOpen: () => {},
    errMsg: '',
    setErrMsg: () => {},
    setIsOpen: () => {},
    setTestId: () => {},
    isDrawerOpen: false,
    setLoading: () => ({}),
    setMoreTests: () => {},
    setIsDrawerOpen: () => {},
    setSelectedTests: () => ({}),
    selectedVendor: generateLabTest().vendorLabItems[0],
    setSelectedVendor: () => undefined,
    defaultVendor: { assignedZone: '', id: '', branchAddress: '', image: { app: '', web: '' }, name: { en: '', bn: '' }, tag: '' },
    setDefaultVendor: () => undefined,
    isModalOpen: false,
    setIsModalOpen: () => undefined,
    switchVendorConfirmation: false,
    setSwitchVendorConfirmation: () => undefined,
    filterVendor: undefined,
    setFilterVendor: () => undefined
});

export default function LabTestContextProvider({ children }: { children: ReactNode }) {
    const [testId, setTestId] = useState<string>('');
    const [open, setOpen] = useState<Open>(Open.NONE);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [moreTests, setMoreTests] = useState<Test[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [selectedTests, setSelectedTests] = useState<SelectedTests>({});
    const [orderData, setOrderData] = useState<any>({ id: 1 });
    const [errMsg, setErrMsg] = useState<string>('');
    const [selectedVendor, setSelectedVendor] = useState(generateLabTest().vendorLabItems[0]);
    const [switchVendorConfirmation, setSwitchVendorConfirmation] = useState<boolean>(false);
    const [filterVendor, setFilterVendor] = useState();
    const [defaultVendor, setDefaultVendor] = useState<Vendor>({
        assignedZone: '',
        id: '',
        branchAddress: '',
        image: { app: '', web: '' },
        name: { en: '', bn: '' },
        tag: ''
    });

    const contextValue = {
        open,
        isOpen,
        testId,
        loading,
        setOpen,
        moreTests,
        setIsOpen,
        errMsg,
        setErrMsg,
        orderData,
        setOrderData,
        setTestId,
        setLoading,
        isDrawerOpen,
        setMoreTests,
        selectedTests,
        setIsDrawerOpen,
        setSelectedTests,
        selectedVendor,
        setSelectedVendor,
        defaultVendor,
        setDefaultVendor,
        isModalOpen,
        setIsModalOpen,
        switchVendorConfirmation,
        setSwitchVendorConfirmation,
        filterVendor,
        setFilterVendor
    };

    return (
        <LabTestContext.Provider value={contextValue}>
            <LabCartProvider>
                <LabTestSearchContextProvider>
                    <LabTestCategoryContextProvider>
                        {children}
                        {open === Open.ITEM_UNAVAILABLE ? (
                            <NaModal errorTag={ErrorTag.ITEM_UNAVAILABLE} message={errMsg} />
                        ) : open === Open.SERVICE_UNAVAILABLE ? (
                            <NaModal errorTag={ErrorTag.SERVICE_UNAVAILABLE} message={errMsg} />
                        ) : open === Open.LOCATION_NOT_SET ? (
                            <NaModal errorTag={ErrorTag.LOCATION_NOT_SET} message={errMsg} />
                        ) : open === Open.ITEM_MAX_REACHED ? (
                            <NaModal errorTag={ErrorTag.ITEM_MAX_REACHED} message={errMsg} />
                        ) : null}
                        <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
                            {open === Open.LOGIN && <LoginCompo />}
                            {open === Open.MORE_TEST && <MoreTestList data={moreTests} />}
                        </Modal>
                        {isDrawerOpen && (
                            <Drawer>
                                {open === Open.SORT_BY && <SortByModal />}
                                {open === Open.FILTER_BY && <FilterByModal />}
                                {open === Open.BOOK_TEST && <BookTestDrawer />}
                                {(open === Open.VENDORS_MODAL || open === Open.FILTER_VENDORS_MODAL) && <VendorsModal />}
                            </Drawer>
                        )}
                        {isModalOpen && <LabModal>{Open.SWITCH_VENDOR_MODAL && <SwitchVendorModal />}</LabModal>}
                        {!isDrawerOpen && open === Open.BOOK_TEST && (
                            <BookModal>
                                <PatientList />
                            </BookModal>
                        )}
                    </LabTestCategoryContextProvider>
                </LabTestSearchContextProvider>
            </LabCartProvider>
        </LabTestContext.Provider>
    );
}

export const useLabTestContext = () => useContext(LabTestContext);
