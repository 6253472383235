import type { LabCart, LabCartContext } from '@/services/types/lab-test/cart';
import type { MostBookedTest } from '@/services/types/lab-test/tests';

export function generateLabCartContext(): LabCartContext {
    return {
        getLabCart: async () => undefined,
        testsInCart: { '': { patientCount: 0 } },
        setTestsInCart: () => undefined,
        addToCart: async () => undefined,
        removeFromCart: async () => undefined,
        loading: false,
        setLoading: () => undefined,
        labCart: generateLabCart(),
        setLabCart: () => undefined,
        test: generateLabTest(),
        setTest: () => undefined,
        note: '',
        setNote: () => undefined,
        scheduleDate: { dateValue: '', times: [], title: { en: '', bn: '' } },
        scheduleTimeUqid: '',
        setScheduleDate: () => undefined,
        setScheduleTimeUqid: () => undefined,
        isHardCopyRequired: false,
        setIsHardCopyRequired: () => undefined,
        patientCount: 0,
        setPatientCount: () => undefined
    };
}

export function generateLabTest(): MostBookedTest {
    return {
        id: '',
        icon: '',
        itemType: '',
        vendorLabItems: [
            {
                basePrice: '',
                discountPercent: '',
                discountPrice: '',
                externalMaterialCost: '',
                id: '',
                margin: '',
                materialCost: '',
                vendor: {
                    id: '',
                    image: { app: '', web: '' },
                    isDefault: false,
                    name: { en: '', bn: '' },
                    tag: '',
                    status: '',
                    totalTest: 0
                },
                regularPrice: '',
                status: ''
            }
        ],
        description: { en: '', bn: '' },
        bannerImage: {
            web: '',
            app: ''
        },
        name: {
            en: '',
            bn: ''
        },
        slug: '',
        knownAs: [],
        sampleRequirements: [],
        testRequirements: [],
        reportAvailableHour: 12,
        subTitle: {
            en: '',
            bn: ''
        },
        bookedCount: 0,
        testCount: 0,
        status: '',
        _rankingScore: 0
    };
}

export function generateLabCart(): LabCart {
    return {
        id: '',
        userId: '',
        location: {
            id: '',
            division: '',
            district: '',
            area: '',
            lat: 0,
            long: 0,
            hubId: '',
            zone: ''
        },
        userLocation: {
            id: '',
            userId: '',
            locationId: '',
            subareaId: '',
            name: '',
            mobileNumber: '',
            type: '',
            address: '',
            isDefault: false,
            lat: 0,
            long: 0,
            location: '',
            isChecked: false,
            division: '',
            district: '',
            area: '',
            hubId: '0',
            zone: '',
            subareaTitle: '',
            subareaZone: ''
        },
        itemCount: 0,
        subtotalAmount: '',
        priceDiscount: '',
        promoDiscount: '0',
        discountAmount: '',
        roundingOff: '0',
        labMaterialCharge: '',
        collectionConveyance: '',
        shouldApplyCollectionConveyance: true,
        isHardCopyRequired: true,
        hardCopyConveyance: '',
        shouldApplyHardCopyConveyance: true,
        cash: '0',
        cashWithoutConveyance: '0',
        totalAmount: '',
        totalAmountWithoutConveyance: '',
        items: [
            {
                id: '',
                itemType: '',
                item: {
                    id: '',
                    itemType: '',
                    name: {
                        en: '',
                        bn: ''
                    },
                    slug: '',
                    subtitle: {
                        en: '',
                        bn: ''
                    },
                    knownAs: [],
                    sampleRequirements: [
                        {
                            en: '',
                            bn: ''
                        }
                    ],
                    testRequirements: [],
                    reportAvailableHour: 0,
                    regularPrice: '',
                    discountPrice: '',
                    isDiscountEnabled: false,
                    discountPercent: '',
                    icon: '',
                    bannerImage: {
                        app: '',
                        web: ''
                    },
                    hintMessage: '',
                    testCount: 0,
                    vendorLabItems: [],
                    isUnavailable: false,
                    tests: []
                },
                patientCount: 0,
                unitPrice: '',
                unitPriceDiscount: '',
                unitPriceDiscountPercent: '',
                subtotalAmount: '',
                discountAmount: '',
                totalAmount: ''
            }
        ],
        promoCode: '',
        createdAt: '',
        updatedAt: '',
        vendor: {
            tag: '',
            assignedZone: '',
            branchAddress: '',
            id: '',
            image: {
                app: '',
                web: ''
            },
            name: {
                en: '',
                bn: ''
            }
        }
    };
}
