'use client';

import { lt_close } from '@/lab-test/svg';
import { MouseEvent, ReactNode } from 'react';
import { useLabCartContext } from '@/lab-test/context/lab-cart-context';
import { useLabTestContext } from '@/lab-test/context/lab-test-context';
import { generateLabTest } from '@/services/utils/generators';

import { Open } from '@/services/types/lab-test';

import Title from '@/lab-test/atoms/title';
import Spinner from '@/lab-test/atoms/spinner';

import styles from './index.module.scss';

interface LtModalProps {
    children: ReactNode;
    title?: string;
    closeButton?: boolean;
    fullWidth?: boolean;
    fixedHeight?: boolean;
}

export default function BookModal({ children, title, closeButton = true, fullWidth = false, fixedHeight = true }: LtModalProps) {
    const { setOpen, loading } = useLabTestContext();
    const { loading: cartLoading, setTest } = useLabCartContext();

    function handleClick(event: MouseEvent<any>) {
        event.stopPropagation();
        setOpen((prev) => (prev = Open.NONE));
        setTest((prev) => (prev = generateLabTest()));

        document.body.style.overflow = 'auto';
    }

    return (
        <div className={styles['book-modal-wrapper']} onClick={handleClick}>
            <div
                className={`${styles['book-modal']} ${fullWidth && styles['book-modal-full']}`}
                onClick={preventFromClosingModal}
                style={{ maxHeight: fixedHeight ? '245px' : 'auto' }}
            >
                <div className={styles['book-modal-head']}>
                    <Title content={title || 'Book for'} />
                    {closeButton && <div onClick={handleClick}>{lt_close}</div>}
                </div>
                <div className={styles['book-modal-bottom']}>
                    {children}
                    {(loading || cartLoading) && (
                        <div className={styles['spinner-wrapper']}>
                            <Spinner />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

function preventFromClosingModal(event: MouseEvent) {
    event.stopPropagation();
}
