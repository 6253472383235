import styles from './index.module.scss';

interface DiscountChip {
    reverse?: boolean;
    discountPercent: string | number;
}

export default function DiscountChip({ reverse = false, discountPercent }: DiscountChip) {
    return (
        <div className={styles['discount-chip-v2']}>
            {reverse ? (
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15" fill="none">
                    <path d="M11.9226 15L6.5 7.5L11.9226 -1.37905e-07L13.5 0L13.5 15L11.9226 15Z" fill="#FD6A6A" />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M5.5 7.5L11 15L0 15L5.5 7.5ZM1.31134e-06 -9.61651e-07L5.5 7.5L11 0L1.31134e-06 -9.61651e-07Z"
                        fill="#FE9A9A"
                    />
                </svg>
            ) : (
                <svg xmlns="http://www.w3.org/2000/svg" width="8" height="15" viewBox="0 0 8 15" fill="none">
                    <path d="M5.49999 15L7.74859e-07 7.5L5.49999 -9.73535e-07L8 -1.19209e-06L8 15L5.49999 15Z" fill="#FD6A6A" />
                </svg>
            )}
            <span>{parseInt(discountPercent as string)}% OFF</span>
            {reverse ? (
                <svg xmlns="http://www.w3.org/2000/svg" width="8" height="15" viewBox="0 0 8 15" fill="none">
                    <path d="M2.50001 5.77617e-07L8 7.5L2.5 15L-3.8466e-06 15L0 0L2.50001 5.77617e-07Z" fill="#FD6A6A" />
                </svg>
            ) : (
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15" fill="none">
                    <path d="M1.57745 0L7 7.5L1.57745 15H0V0H1.57745Z" fill="#FD6A6A" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M8 7.5L2.5 0H13.5L8 7.5ZM13.5 15L8 7.5L2.5 15H13.5Z" fill="#FE9A9A" />
                </svg>
            )}
        </div>
    );
}
