'use client';

import { useEffect } from 'react';

import { CheckCircle, Cancel } from '@/lab-test/icons';

import Link from 'next/link';

import toast from 'react-hot-toast';
import styles from './index.module.scss';

export default function LabToast({ type, message }: LabToast) {
    useEffect(() => {
        const dismissToast = setTimeout(() => {
            toast.dismiss();
        }, 2000);
        return () => clearTimeout(dismissToast);
    }, []);

    return (
        <div className={styles['lab-toast']}>
            <div className={styles['toast-message']}>
                <div className={styles['icon']}>{type === 'success' ? <CheckCircle fill="#0e7673" /> : <Cancel fill="#fd6a6a" />}</div>
                <div>{message}</div>
            </div>
            {/* {type !== 'remove' && <Link href="/lab-test/cart">View Cart</Link>} */}
        </div>
    );
}

type LabToast = {
    type: 'success' | 'failed';
    message: string;
};
