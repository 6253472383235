import { lt_checkbox, lt_science } from '@/lab-test/svg';

import { Fragment } from 'react';

import Title from '@/lab-test/atoms/title';

import styles from './index.module.scss';

export default function MoreTestList({ data }: MoreTestList) {
    return (
        <Fragment>
            <div className={styles['tests-list-top']}>
                <div className={styles['icon']}>{lt_science}</div>
                <Title content={`Package Includes ${data?.length} Tests`} />
            </div>
            {data?.map((item: any) => (
                <div key={item.id} className={styles['test-item']}>
                    <div className={styles['icon']}>{lt_checkbox}</div>
                    <span>{item?.name?.en || item?.test?.name?.en}</span>
                </div>
            ))}
        </Fragment>
    );
}

type MoreTestList = {
    data: any;
};
