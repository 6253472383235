'use client';

import { createContext, useContext, useState, useEffect } from 'react';
import { getTestsCart, addTestToCart, deleteTestCartItem } from '@/services/apis/lab-test/cart';
import { generateLabCartContext, generateLabCart, generateLabTest } from '@/services/utils/generators';
import { useLabTestPixelEvent } from '@/services/event-log-tracking/useLabTestPixelEvent';
import { useLabTestContext } from './lab-test-context';

import { Open } from '@/services/types/lab-test';

import type { ReactNode } from 'react';
import type { TestsInCart, LabCart } from '@/services/types/lab-test/cart';
import type { Schedules } from '@/services/types/lab-test/cart copy';

import LabToast from '@/lab-test/lab-toast';

import toast from 'react-hot-toast';
import useAuth from '@/services/hooks/use-auth';

const LabCartContext = createContext(generateLabCartContext());

export default function LabCartProvider({ children }: { children: ReactNode }) {
    const { user } = useAuth();
    const {
        testId,
        setOpen,
        selectedVendor,
        setIsDrawerOpen,
        setDefaultVendor,
        defaultVendor,
        setSwitchVendorConfirmation,
        setSelectedVendor
    } = useLabTestContext();
    const { LabTestAddToCartEvent } = useLabTestPixelEvent();

    const [labCart, setLabCart] = useState(generateLabCart());
    const [testsInCart, setTestsInCart] = useState<TestsInCart>({});
    const [loading, setLoading] = useState<boolean>(false);
    const [test, setTest] = useState(generateLabTest());
    const [scheduleDate, setScheduleDate] = useState<Schedules>({ dateValue: '', times: [], title: { bn: '', en: '' } });
    const [scheduleTimeUqid, setScheduleTimeUqid] = useState<string>('');
    const [note, setNote] = useState<string>('');
    const [isHardCopyRequired, setIsHardCopyRequired] = useState<boolean>(false);
    const [patientCount, setPatientCount] = useState<number>(1);

    async function getLabCart() {
        const response = await getTestsCart();

        if (response?.success) {
            setLabCart((prev) => ({ ...prev, ...response?.data }));
            setDefaultVendor((prev) => ({ ...prev, ...response?.data?.vendor }));
            response?.data?.items?.map((item: any) => {
                setTestsInCart((prev) => ({ ...prev, [item?.item?.id]: { patientCount: item?.patientCount } }));
            });
        }
    }

    async function addToCart(patientCount: number) {
        setLoading((prev) => (prev = true));
        const response = await addTestToCart({
            labItemUqid: testId,
            patientCount,
            vendorUqid: selectedVendor?.vendor?.id || defaultVendor.id
        });
        setLoading((prev) => (prev = false));

        if ('errorTag' in response?.data) {
            setIsDrawerOpen((prev) => (prev = false));
            setOpen((prev) => (prev = Open.NONE));
            alert(response?.message);
        } else if (response?.success) {
            const data = response?.data as LabCart;
            LabTestAddToCartEvent({ testData: test, patientCount });
            setLabCart((prev) => ({ ...prev, ...response?.data }));
            setTestsInCart((prev) => ({ ...prev, [testId]: { patientCount } }));
            setOpen((prev) => (prev = Open.NONE));
            setTest((prev) => ({ ...prev, ...generateLabTest() }));
            setIsDrawerOpen((prev) => (prev = false));
            setDefaultVendor((prev) => ({ ...prev, ...data?.vendor }));
            toast(() => <LabToast type="success" message={response?.message} />);
            setSwitchVendorConfirmation((prev) => (prev = false));
            setSelectedVendor((prev) => ({
                ...prev,
                basePrice: '',
                discountPercent: '',
                discountPrice: '',
                externalMaterialCost: '',
                id: '',
                margin: '',
                materialCost: '',
                regularPrice: '',
                status: '',
                vendor: {
                    id: '',
                    image: { app: '', web: '' },
                    name: { en: '', bn: '' },
                    isDefault: false,
                    status: '',
                    tag: '',
                    totalTest: 0
                }
            }));
        }

        document.body.style.overflow = 'auto';
    }

    async function removeFromCart(testId: string) {
        setLoading((prev) => (prev = true));
        const response = await deleteTestCartItem(testId);
        setLoading((prev) => (prev = false));

        if (response?.success) {
            setLabCart((prev) => ({ ...prev, ...response?.data }));
            setTestsInCart((prev) => {
                delete prev[testId];
                return prev;
            });
            setOpen((prev) => (prev = Open.NONE));
            toast(() => <LabToast type="success" message={response?.message} />);
            document.body.style.overflow = 'auto';
        }
    }

    const value = {
        getLabCart,
        testsInCart,
        setTestsInCart,
        addToCart,
        removeFromCart,
        loading,
        setLoading,
        labCart,
        setLabCart,
        test,
        setTest,
        scheduleDate,
        setScheduleDate,
        scheduleTimeUqid,
        setScheduleTimeUqid,
        note,
        setNote,
        isHardCopyRequired,
        setIsHardCopyRequired,
        patientCount,
        setPatientCount
    };

    useEffect(() => {
        user && getLabCart();
        // eslint-disable-next-line
    }, [user]);

    return <LabCartContext.Provider value={value}>{children}</LabCartContext.Provider>;
}

export const useLabCartContext = () => useContext(LabCartContext);
