import { useLabTestContext } from '@/lab-test/context/lab-test-context';
import { useLabTestCategoryContext } from '@/lab-test/context/lab-test-category-context';
import { useState, useEffect } from 'react';
import { useSearchParams } from 'next/navigation';

import { ChangeEvent } from 'react';
import { Open } from '@/services/types/lab-test';
import { FilterBy } from '@/services/types/lab-test';
import { RadioChecked, RadioUnchecked } from '@/lab-test/svg-icon-components';

import styles from './index.module.scss';

const labels: FilterBy[] = [
    { id: 1, label: '0 - 500', min: 0, max: 500, value: '500' },
    { id: 2, label: '500 - 1000', min: 500, max: 1000, value: '1000' },
    { id: 3, label: '1000 - 1400', min: 1000, max: 1400, value: '1400' }
];

export default function FilterByModal() {
    const searchParams = useSearchParams();

    const { setOpen, setIsDrawerOpen, defaultVendor } = useLabTestContext();
    const { setPage, setTests, filterBy, setFilterBy, setSortFilter } = useLabTestCategoryContext();

    const [filter, setFilter] = useState<FilterBy>({ id: 0, label: '', value: '', max: 0, min: 0 });
    const [priceThreshold, setPriceThreshold] = useState<{ min: number; max: number }>({ min: 0, max: 0 });

    function handleFilterBy(filter: FilterBy) {
        setFilter((prev) => ({ ...prev, id: filter.id, label: filter.label, value: filter.value }));
        setPriceThreshold((prev) => ({ ...prev, min: filter.min, max: filter.max }));
        setSortFilter((prev) => ({
            ...prev,
            vendor: searchParams.get('lab') || defaultVendor.tag || JSON.parse(localStorage.getItem('arogga_default_lab') as string)?.tag,
            priceThreshold: `0,${filter.max}`
        }));
    }

    function handlePriceThreshold(event: ChangeEvent<HTMLInputElement>, type: 'min' | 'max') {
        setSortFilter((prev) => ({ ...prev, vendor: searchParams.get('lab') || defaultVendor.tag }));
        if (type === 'min') {
            setPriceThreshold((prev) => ({ ...prev, min: parseInt(event.target.value) }));
        } else {
            setPriceThreshold((prev) => ({ ...prev, max: parseInt(event.target.value) }));
        }
    }

    /* function handleApply() {
        if (filter.id !== 0 || (filter.max !== 0 && filter.max > filter.min) || (filterBy.max !== 0 && filterBy.max > filterBy.min)) {
            setPage((prev) => (prev = 1));
            setTests((prev) => (prev = []));
            setFilterBy((prev) => ({ ...prev, id: filter.id, label: filter.label, max: filter.max, min: filter.min, value: filter.value }));
            setIsDrawerOpen((prev) => (prev = false));
            setOpen((prev) => (prev = Open.NONE));
        }
    } */

    function handleApply() {
        if (filter.id === 0 && priceThreshold.min === 0 && priceThreshold.max === 0) {
            alert('Please select a filter');
        } else if (priceThreshold.min === 0 && priceThreshold.max === 0) {
            alert('Please enter a valid minimum and maximum price');
        } else if (priceThreshold.min > priceThreshold.max) {
            alert('Minimum price cannot be greater than maximum price');
        } else {
            setPage((prev) => (prev = 1));
            setTests((prev) => (prev = []));
            setFilterBy((prev) => ({ ...prev, id: filter.id, label: filter.label, max: filter.max, min: filter.min, value: filter.value }));
            setSortFilter((prev) => ({ ...prev, priceThreshold: `${priceThreshold.min},${priceThreshold.max}` }));
            setIsDrawerOpen((prev) => (prev = false));
            setOpen((prev) => (prev = Open.NONE));
        }

        document.body.style.overflow = 'auto';
    }

    function handleClear() {
        if (filterBy.id !== 0) {
            setTests((prev) => (prev = []));
            setPage((prev) => (prev = 1));
            setFilterBy((prev) => ({ ...prev, id: 0, label: '', value: '', max: 0, min: 0 }));
            setOpen((prev) => (prev = Open.NONE));
            setSortFilter((prev) => {
                const { price, discount, priceThreshold, vendor, ...rest } = prev;
                if (price) {
                    return { ...rest, price, vendor };
                } else if (discount) {
                    return { ...rest, discount, vendor };
                } else {
                    return { ...rest };
                }
            });
            setIsDrawerOpen((prev) => (prev = false));
        } else if (filter.id !== 0) {
            setFilter((prev) => ({ ...prev, id: 0, label: '', max: 0, min: 0, value: '' }));
        }

        document.body.style.overflow = 'auto';
    }

    useEffect(() => {
        if (filterBy.id > 0) {
            setFilter((prev) => ({
                ...prev,
                id: filterBy.id,
                label: filterBy.label,
                max: filterBy.max,
                min: filterBy.min,
                value: filterBy.value
            }));
        }
    }, [filterBy]);

    /* function handleClear() {
        setTests((prev) => (prev = []));
        setMinMax((prev) => ({ ...prev, min: 0, max: 0 }));
        setPage((prev) => (prev = 1));
        setFilterBy((prev) => (prev = { id: 0, label: '', value: '' }));
        setOpen((prev) => (prev = Open.NONE));
        setIsDrawerOpen((prev) => (prev = false));
    }

    function handleApply() {
        setTests((prev) => (prev = []));
        minMax.max > 0 && setFilterBy((prev) => ({ ...prev, min: minMax.min, max: minMax.max }));
        setOpen((prev) => (prev = Open.NONE));
        setIsDrawerOpen((prev) => (prev = false));
    } */

    return (
        <div className={styles['filter-by-modal']}>
            <div className={styles['filter-by-modal-top']}>
                <div>Filter by price</div>
            </div>
            <div className={styles['filter-by-modal-bottom']}>
                {labels.map((label) => (
                    <div key={label.id} className={styles['filter-by-label']} onClick={() => handleFilterBy(label)}>
                        {filter.id === label.id ? <RadioChecked /> : <RadioUnchecked />}
                        <span>{label.label}</span>
                    </div>
                ))}
                <div className={styles['min-max']}>
                    <input
                        type="number"
                        placeholder="৳ Min"
                        defaultValue={filterBy.min > 0 ? filterBy.min : ''}
                        value={priceThreshold.min}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => handlePriceThreshold(event, 'min')}
                    />{' '}
                    to
                    <input
                        type="number"
                        placeholder="৳ Max"
                        defaultValue={filterBy.max > 0 ? filterBy.max : ''}
                        value={priceThreshold.max}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => handlePriceThreshold(event, 'max')}
                    />
                </div>
            </div>
            <div className={styles['filter-by-actions']}>
                <button className={styles['clear-btn']} onClick={handleClear}>
                    Clear Filters
                </button>
                <button className={styles['apply-btn']} onClick={handleApply}>
                    Apply
                </button>
            </div>
        </div>
    );
}
