'use client';

import { useLabTestContext } from '@/lab-test/context/lab-test-context';
import { useLabCartContext } from '@/lab-test/context/lab-cart-context';

import { ArrowBack } from '@/lab-test/icons';
import { Open } from '@/services/types/lab-test';

import Image from 'next/image';

import styles from './index.module.scss';

export default function SwitchVendorModal() {
    const { defaultVendor, selectedVendor, setIsDrawerOpen, setOpen, setIsModalOpen, setSelectedVendor, setSwitchVendorConfirmation } =
        useLabTestContext();
    const { addToCart, patientCount, loading } = useLabCartContext();

    function closeLabModal() {
        setIsModalOpen((prev) => (prev = false));
        setOpen((prev) => (prev = Open.NONE));
        setSelectedVendor((prev) => ({
            ...prev,
            basePrice: '',
            discountPercent: '',
            discountPrice: '',
            externalMaterialCost: '',
            id: '',
            margin: '',
            materialCost: '',
            regularPrice: '',
            status: '',
            vendor: { id: '', image: { app: '', web: '' }, name: { en: '', bn: '' }, isDefault: false, status: '', tag: '', totalTest: 0 }
        }));

        document.body.style.overflow = 'auto';
    }

    async function confirmSwitchVendor() {
        await addToCart(patientCount);

        setIsModalOpen((prev) => (prev = false));
        setOpen((prev) => (prev = Open.NONE));
        setIsDrawerOpen((prev) => (prev = false));
        setSwitchVendorConfirmation((prev) => (prev = false));
    }

    return (
        <div className={styles['switch-vendor-modal']}>
            <div className={styles['from-to']}>
                <div className={styles['from']}>
                    <Image src={defaultVendor.image.app} alt={defaultVendor.name.en} width={100} height={100} />
                </div>
                <div className={styles['icon']}>
                    <ArrowBack style={{ transform: 'rotate(180deg)' }} />
                </div>
                <div className={styles['from']}>
                    <Image src={selectedVendor?.vendor?.image?.app} alt={selectedVendor?.vendor?.name?.en} width={100} height={100} />
                </div>
            </div>
            <div className={styles['switch-vendor-content']}>
                <h2>Changing your Lab</h2>
                <div>
                    Do you want to change your previous lab selection from Lab A to Lab B?{' '}
                    <span>
                        {defaultVendor?.name?.en} to {selectedVendor?.vendor?.name?.en}?
                    </span>
                </div>
            </div>
            <div className={styles['switch-vendor-actions']}>
                <button type="button" onClick={closeLabModal}>
                    Cancel
                </button>
                <button type="button" onClick={confirmSwitchVendor} disabled={loading}>
                    Confirm
                </button>
            </div>
        </div>
    );
}
