'use client';

import { useState, useEffect } from 'react';
import { useLabCartContext } from '@/lab-test/context/lab-cart-context';
import { useLabTestContext } from '@/lab-test/context/lab-test-context';

import { RadioButtonUnchecked, RadioButtonChecked, Add, Minus, Info } from '@/lab-test/icons';
import { Open } from '@/services/types/lab-test';

import type { VendorLabItem } from '@/services/types/lab-test/tests';

import Image from 'next/image';
import Pricing from '@/lab-test/atoms/pricing';

import styles from './index.module.scss';

export default function BookTestDrawer() {
    const { test, addToCart, patientCount: pc, setPatientCount: setPc, labCart, loading } = useLabCartContext();
    const { selectedVendor, setSelectedVendor, setIsDrawerOpen, setOpen, setIsModalOpen, defaultVendor, switchVendorConfirmation } =
        useLabTestContext();

    const foundInCart = labCart.items.find((item) => item.item.id === test.id);

    const [patientCount, setPatientCount] = useState(pc);

    function handlePatientCount(type: 'increment' | 'decrement') {
        if (type === 'increment') {
            if (patientCount < 5) {
                setPc((prev) => prev + 1);
                setPatientCount((prev) => prev + 1);
            }
        } else {
            if (patientCount > 1) {
                setPc((prev) => prev - 1);
                setPatientCount((prev) => prev - 1);
            }
        }
    }

    function handleVendorSelection(vendor: VendorLabItem) {
        vendor.status === 'active' && setSelectedVendor((prev) => ({ ...prev, ...vendor }));
    }

    async function handleAddToCart() {
        if (selectedVendor?.vendor?.id !== defaultVendor?.id) {
            setIsDrawerOpen((prev) => (prev = false));
            setIsModalOpen((prev) => (prev = true));
            setOpen(Open.SWITCH_VENDOR_MODAL);
        } else {
            await addToCart(patientCount);
            setPc((prev) => (prev = 1));
        }
    }

    useEffect(() => {
        setSelectedVendor((prev) => ({
            ...prev,
            ...test?.vendorLabItems?.find((vendorLabItem) => vendorLabItem.vendor.id === defaultVendor.id)
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [test, labCart, defaultVendor]);

    return (
        <div className={styles['book-test-drawer']}>
            <div className={styles['selected-test']}>
                <div className={styles['test-info']}>
                    <div className={styles['test-image']}>
                        <Image src={test.bannerImage.app} alt={test.name.en} width={84} height={84} />
                    </div>
                    <div className={styles['test-content']}>
                        <div className={styles['test-name']}>{test.name.en}</div>
                        <div className={styles['test-description']}>{test.subTitle.en}</div>
                        <div className={styles['included-test']}>
                            {test.itemType === 'test' ? 'Includes 1 Test' : `Includes ${test.testCount} Tests`}
                        </div>
                    </div>
                </div>
                <div className={styles['patient-selector']}>
                    <div className={styles['label']}>Select Patients</div>
                    <div className={styles['counter']}>
                        <button type="button" onClick={() => handlePatientCount('decrement')}>
                            <Minus width={14} height={14} fill="#323B49" />
                        </button>
                        <div className={styles['count']}>
                            {patientCount} {patientCount > 1 ? 'Patients' : 'Patient'}
                        </div>
                        <button type="button" onClick={() => handlePatientCount('increment')}>
                            <Add width={14} height={14} fill="#FFFFFF" />
                        </button>
                    </div>
                </div>
            </div>
            <div className={styles['warning-message-wrapper']}>
                <div className={styles['info-message']}>
                    <div className={styles['icon']}>
                        <Info fill={labCart.itemCount > 0 ? '#0062FF' : '#0062FF'} width={14} height={14} />
                    </div>
                    <div>
                        {labCart.itemCount > 0 ? 'Changing lab will change your cart lab.' : 'Only one vendor can be selected per order.'}
                    </div>
                </div>
            </div>
            <div className={styles['selected-lab']}>
                <h2 className={styles['title']}>Current Lab</h2>
                {test?.vendorLabItems
                    ?.filter((vendorLabItem) => vendorLabItem.vendor.id === labCart.vendor.id)
                    ?.map((vendorLabItem, index) => (
                        <div
                            key={index}
                            className={
                                vendorLabItem.status === 'inactive'
                                    ? styles['lab-inactive']
                                    : vendorLabItem.id === selectedVendor?.id
                                      ? styles['lab-active']
                                      : styles['lab']
                            }
                            onClick={() => handleVendorSelection(vendorLabItem)}
                        >
                            <div className={styles['icon']}>
                                {vendorLabItem.id === selectedVendor?.id && vendorLabItem.status !== 'inactive' ? (
                                    <RadioButtonChecked fill="#0e7673" />
                                ) : (
                                    <RadioButtonUnchecked fill={(vendorLabItem.status === 'inactive' && '#a0aec0') || ''} />
                                )}
                            </div>
                            <div className={styles['lab-info']}>
                                <div className={styles['lab-image']}>
                                    <Image src={vendorLabItem.vendor.image.app} alt={vendorLabItem.vendor.name.en} width={48} height={48} />
                                </div>
                                <div className={styles['lab-content']}>
                                    <div
                                        className={
                                            vendorLabItem.status === 'active'
                                                ? styles['lab-name-wrapper']
                                                : styles['lab-name-wrapper-inactive']
                                        }
                                    >
                                        <div className={styles['lab-name']}>{vendorLabItem.vendor.name.en || ''}</div>
                                        {vendorLabItem.status === 'inactive' && <div className={styles['inactive-label']}>Not Offered</div>}
                                    </div>
                                    <div className={styles['pricing-wrapper']}>
                                        {vendorLabItem.status === 'inactive' ? (
                                            <div className={styles['inactive-price']}>৳0</div>
                                        ) : (
                                            <Pricing
                                                reversed
                                                showDiscountChip={false}
                                                regularPrice={parseInt(vendorLabItem.regularPrice || '')}
                                                discountPrice={parseInt(vendorLabItem.discountPrice || '')}
                                                discountPercent={vendorLabItem.discountPercent || ''}
                                                variant="grid"
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
            </div>
            <div className={styles['available-labs']} style={{ maxHeight: `calc(${window.innerHeight - 80 - 182 - 64 - 120 - 68}px)` }}>
                <h2 className={styles['title']}>Change Lab</h2>
                <div className={styles['labs']}>
                    {test?.vendorLabItems
                        ?.filter((vendorLabItem) => vendorLabItem.vendor.id !== defaultVendor.id)
                        ?.map((vendorLabItem, index) => (
                            <div
                                key={index}
                                className={
                                    vendorLabItem.status === 'inactive'
                                        ? styles['lab-inactive']
                                        : vendorLabItem.id === selectedVendor?.id
                                          ? styles['lab-active']
                                          : styles['lab']
                                }
                                // className={vendorLabItem.id === selectedVendor?.id ? styles['lab-active'] : styles['lab']}
                                onClick={() => handleVendorSelection(vendorLabItem)}
                            >
                                <div className={styles['icon']}>
                                    {vendorLabItem.id === selectedVendor?.id ? (
                                        <RadioButtonChecked fill="#0e7673" />
                                    ) : (
                                        <RadioButtonUnchecked fill={(vendorLabItem.status === 'inactive' && '#a0aec0') || ''} />
                                    )}
                                </div>
                                <div className={styles['lab-info']}>
                                    <div className={styles['lab-image']}>
                                        <Image
                                            src={vendorLabItem.vendor.image.app}
                                            alt={vendorLabItem.vendor.name.en}
                                            width={48}
                                            height={48}
                                        />
                                    </div>
                                    <div className={styles['lab-content']}>
                                        <div
                                            className={
                                                vendorLabItem.status === 'active'
                                                    ? styles['lab-name-wrapper']
                                                    : styles['lab-name-wrapper-inactive']
                                            }
                                        >
                                            <div className={styles['lab-name']}>{vendorLabItem.vendor.name.en || ''}</div>
                                            {vendorLabItem.status === 'inactive' && (
                                                <div className={styles['inactive-label']}>Not Offered</div>
                                            )}
                                        </div>
                                        <div className={styles['pricing-wrapper']}>
                                            {vendorLabItem.status === 'inactive' ? (
                                                <div className={styles['inactive-price']}>৳0</div>
                                            ) : (
                                                <Pricing
                                                    reversed
                                                    showDiscountChip={false}
                                                    regularPrice={parseInt(vendorLabItem.regularPrice || '')}
                                                    discountPrice={parseInt(vendorLabItem.discountPrice || '')}
                                                    discountPercent={vendorLabItem.discountPercent || ''}
                                                    variant="grid"
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                </div>
            </div>
            <div className={styles['drawer-action']}>
                <button
                    type="button"
                    className={
                        (selectedVendor?.vendor?.id === labCart.vendor.id && foundInCart?.patientCount === patientCount) ||
                        test.vendorLabItems.find((vendorLabItem) => vendorLabItem.vendor.id === selectedVendor?.vendor?.id)?.status ===
                            'inactive'
                            ? styles['action-button-disabled']
                            : styles['action-button']
                    }
                    onClick={handleAddToCart}
                    disabled={
                        (selectedVendor?.vendor?.id === labCart.vendor.id && foundInCart?.patientCount === patientCount) ||
                        test.vendorLabItems.find((vendorLabItem) => vendorLabItem.vendor.id === selectedVendor?.vendor?.id)?.status ===
                            'inactive' ||
                        loading
                    }
                >
                    Add to Cart
                </button>
            </div>
        </div>
    );
}
