'use client';

import { getSearchedLabTests } from '@/services/apis/lab-test/tests';
import { createContext, useContext, useEffect, useState } from 'react';

import type { ReactNode, Dispatch, SetStateAction } from 'react';
import type { MostBookedTest } from '@/services/types/lab-test/tests';

export interface LabTestSearchContextType {
    page: number;
    loading: boolean;
    tests: MostBookedTest[];
    testCount: number;
    recentSearchList: string[];
    setPage: Dispatch<SetStateAction<number>>;
    setLoading: Dispatch<SetStateAction<boolean>>;
    setTests: Dispatch<SetStateAction<MostBookedTest[]>>;
    setTestCount: Dispatch<SetStateAction<number>>;
    setRecentSearchList: Dispatch<SetStateAction<string[]>>;
    getTests: ({ page, query }: { page: number; query: string }) => void;
}

export const LabTestContext = createContext<LabTestSearchContextType>({
    page: 1,
    tests: [],
    testCount: 0,
    loading: true,
    setPage: () => ({}),
    recentSearchList: [],
    getTests: () => ({}),
    setTests: () => ({}),
    setLoading: () => ({}),
    setTestCount: () => ({}),
    setRecentSearchList: () => ({})
});

export default function LabTestSearchContextProvider({ children }: { children: ReactNode }) {
    const [page, setPage] = useState<number>(1);
    const [tests, setTests] = useState<MostBookedTest[]>([]);
    const [testCount, setTestCount] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(true);
    const [recentSearchList, setRecentSearchList] = useState<string[]>([]);

    async function getTests({ page = 1, query = '' }: { page: number; query: string }) {
        setLoading((prev) => (prev = true));
        const response = await getSearchedLabTests({ page, limit: 10, query });
        setLoading((prev) => (prev = false));

        if (response?.success && page === 1) {
            setTests((prev) => (prev = response?.data?.results));
            setTestCount((prev) => (prev = response?.count || 0));
        } else if (response?.success && page > 1) {
            setTests((prev) => [...prev, ...response?.data?.results]);
            setTestCount((prev) => (prev = response?.count || 0));
        }
    }

    useEffect(() => {
        if (typeof window !== 'undefined') {
            setRecentSearchList((prev) => (prev = JSON.parse(localStorage.getItem('rs') as string) || []));
        }
    }, []);

    return (
        <LabTestContext.Provider
            value={{
                tests,
                setTests,
                loading,
                testCount,
                recentSearchList,
                setRecentSearchList,
                setTestCount,
                setLoading,
                page,
                setPage,
                getTests
            }}
        >
            {children}
        </LabTestContext.Provider>
    );
}

export const useLabTestSearchContext = () => useContext(LabTestContext);
