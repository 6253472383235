'use client';

import { useLabTestContext } from '@/lab-test/context/lab-test-context';
import { useLabTestCategoryContext } from '@/lab-test/context/lab-test-category-context';

import { ReactNode, MouseEvent } from 'react';
import { Close } from '@/lab-test/svg-icon-components';
import { Open } from '@/services/types/lab-test';

import styles from './index.module.scss';

export default function Drawer({ children }: { children: ReactNode }) {
    const { setIsDrawerOpen, setOpen, setSelectedVendor } = useLabTestContext();
    const { setSortFilter } = useLabTestCategoryContext();

    function handleClose() {
        document.body.style.overflow = 'unset';
        setOpen((prev) => (prev = Open.NONE));
        setIsDrawerOpen((prev) => (prev = false));
        setSortFilter((prev) => ({}));
        setSelectedVendor((prev) => ({
            ...prev,
            id: '',
            basePrice: '',
            discountPercent: '',
            discountPrice: '',
            externalMaterialCost: '',
            margin: '',
            materialCost: '',
            regularPrice: '',
            status: '',
            vendor: { id: '', name: { bn: '', en: '' }, image: { app: '', web: '' }, isDefault: false, status: '', tag: '', totalTest: 0 }
        }));

        document.body.style.overflow = 'auto';
    }

    return (
        <div className={styles['drawer-wrapper']} onClick={handleClose}>
            <div className={styles['drawer']} onClick={(event: MouseEvent<HTMLDivElement>) => event.stopPropagation()}>
                <div className={styles['icon']} onClick={handleClose}>
                    <Close />
                </div>
                <div className={styles['drawer-body']} style={{ maxHeight: `calc(${window.innerHeight - 80}px)` }}>
                    {children}
                </div>
            </div>
        </div>
    );
}
