'use client';

import { useLabTestContext } from '@/lab-test/context/lab-test-context';

import { MouseEvent, ReactNode } from 'react';
import { Close } from '@/lab-test/icons';
import { Open } from '@/services/types/lab-test';

import styles from './index.module.scss';

export default function LabModal({ children }: LabModal) {
    const { setOpen, setIsModalOpen, setIsDrawerOpen } = useLabTestContext();

    function closeLabModal() {
        setIsModalOpen((prev) => (prev = false));
        setOpen((prev) => (prev = Open.NONE));
        setIsDrawerOpen((prev) => (prev = false));
    }

    return (
        <div className={styles['lab-modal-wrapper']} onClick={closeLabModal}>
            <div className={styles['lab-modal']} onClick={preventFromClosingModal}>
                <button type="button" className={styles['close-button']} onClick={closeLabModal}>
                    <span className={styles['icon']}>
                        <Close style={{ transform: `rotate(180deg)` }} />
                    </span>
                </button>
                {children}
            </div>
        </div>
    );
}

function preventFromClosingModal(event: MouseEvent) {
    event.stopPropagation();
}

type LabModal = {
    children: ReactNode;
};
