import { useLabTestContext } from '@/lab-test/context/lab-test-context';
import { useLabTestCategoryContext } from '@/lab-test/context/lab-test-category-context';
import { useSearchParams } from 'next/navigation';

import { SortBy, Open } from '@/services/types/lab-test';
import { RadioUnchecked, RadioChecked } from '@/lab-test/svg-icon-components';

import styles from './index.module.scss';

const labels: SortBy[] = [
    { id: 1, label: 'Popularity', queryTxt: 'popular', value: 'desc' },
    { id: 2, label: 'Price: low to high', queryTxt: 'price', value: 'asc' },
    { id: 3, label: 'Price: high to low', queryTxt: 'price', value: 'desc' },
    { id: 4, label: 'Discount: high to low', queryTxt: 'discount', value: 'desc' },
    { id: 5, label: 'Discount: low to high', queryTxt: 'discount', value: 'asc' },
    { id: 6, label: 'Alphabetic (A to Z)', queryTxt: 'alphabet', value: 'asc' }
];

export default function SortByModal() {
    const searchParams = useSearchParams();

    const { setOpen, setIsDrawerOpen, defaultVendor } = useLabTestContext();
    const { sortBy, setSortBy, setTests, setPage, setSortFilter } = useLabTestCategoryContext();

    function handleSortBy(item: SortBy) {
        setTests((prev) => (prev = []));
        setPage((prev) => (prev = 1));
        setOpen((prev) => (prev = Open.NONE));
        setIsDrawerOpen((prev) => (prev = false));

        if (item.queryTxt === 'alphabet') {
            setSortFilter((prev) => ({ alphabet: item.value }));
        } else if (item.queryTxt === 'price') {
            setSortFilter((prev) => {
                const { discount, alphabet, popular, ...rest } = prev;
                return {
                    ...rest,
                    vendor:
                        searchParams.get('lab') ||
                        defaultVendor.tag ||
                        JSON.parse(localStorage.getItem('arogga_default_lab') as string)?.tag,
                    price: item.value
                };
            });
        } else if (item.queryTxt === 'discount') {
            setSortFilter((prev) => {
                const { price, alphabet, popular, ...rest } = prev;
                return {
                    ...rest,
                    vendor:
                        searchParams.get('lab') ||
                        defaultVendor.tag ||
                        JSON.parse(localStorage.getItem('arogga_default_lab') as string)?.tag,
                    discount: item.value
                };
            });
        } else {
            setSortFilter((prev) => ({ popular: item.value }));
        }

        setSortBy((prev) => ({ id: item.id, label: item.label, queryTxt: item.queryTxt, value: item.value }));

        document.body.style.overflow = 'auto';
    }

    function handleCealrBtn() {
        setPage((prev) => (prev = 1));
        setTests((prev) => (prev = []));
        setOpen((prev) => (prev = Open.NONE));
        setIsDrawerOpen((prev) => (prev = false));
        setSortBy((prev) => ({ id: 0, label: '', queryTxt: '', value: '' }));
        setSortFilter((prev) => {
            const { price, discount, priceThreshold, ...rest } = prev;
            if (priceThreshold) {
                return { ...rest, priceThreshold };
            } else {
                return {};
            }
        });
    }

    return (
        <div className={styles['sort-by-modal']}>
            <div className={styles['sort-by-modal-top']}>
                <div>Sort by</div>
                {sortBy.id !== 0 && (
                    <div onClick={handleCealrBtn} className={styles['clear-btn']}>
                        Clear sort by
                    </div>
                )}
            </div>
            <div className={styles['sort-by-modal-bottom']}>
                {labels.map((label) => (
                    <div key={label.id} className={styles['sort-by-label']} onClick={() => handleSortBy(label)}>
                        {label.id === sortBy.id ? <RadioChecked /> : <RadioUnchecked />}
                        <span>{label.label}</span>
                    </div>
                ))}
            </div>
        </div>
    );
}
