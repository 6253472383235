'use client';

import { createContext, useContext, useEffect, useState } from 'react';
import { getSortedTests } from '@/services/apis/lab-test/tests';

import { ReactNode, Dispatch, SetStateAction } from 'react';
import { FilterBy, SortBy } from '@/services/types/lab-test';
import { Category } from '@/services/types/lab-test/category';

export interface LabTestCategoryContextTypes {
    tag: string;
    page: number;
    tests: any[];
    loading: boolean;
    sortBy: SortBy;
    testCount: number;
    filterBy: FilterBy;
    setLoading: Dispatch<SetStateAction<boolean>>;
    minMax: { min: number; max: number };
    setMinMax: Dispatch<SetStateAction<{ min: number; max: number }>>;
    setTag: Dispatch<SetStateAction<string>>;
    setPage: Dispatch<SetStateAction<number>>;
    setSortBy: Dispatch<SetStateAction<SortBy>>;
    setTests: Dispatch<SetStateAction<Category[]>>;
    setFilterBy: Dispatch<SetStateAction<FilterBy>>;
    getTestsFromCategory: ({ tag }: { tag: string }) => void;

    sortFilter: SortFilter;
    setSortFilter: Dispatch<SetStateAction<SortFilter>>;
}

const LabTestCategoryContext = createContext<LabTestCategoryContextTypes>({
    page: 1,
    tag: '',
    tests: [],
    testCount: 0,
    loading: true,
    setLoading: () => ({}),
    setTag: () => ({}),
    setPage: () => ({}),
    setTests: () => ({}),
    setSortBy: () => ({}),
    setMinMax: () => ({}),
    setFilterBy: () => ({}),
    minMax: { min: 0, max: 0 },
    getTestsFromCategory: () => ({}),
    sortBy: { id: 0, label: '', queryTxt: '', value: '' },
    filterBy: { id: 0, label: '', value: '', max: 0, min: 0 },

    sortFilter: { vendor: '', tags: '', price: '', priceThreshold: '', discount: '', popular: '', alphabet: '' },
    setSortFilter: () => undefined
});

export default function LabTestCategoryContextProvider({ children }: { children: ReactNode }) {
    const [minMax, setMinMax] = useState<{ min: number; max: number }>({ min: 0, max: 0 });
    const [tag, setTag] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(true);
    const [page, setPage] = useState<number>(1);
    const [tests, setTests] = useState<Category[]>([]);
    const [testCount, setTestCount] = useState<number>(0);
    const [sortBy, setSortBy] = useState<SortBy>({ id: 0, label: '', queryTxt: '', value: '' });
    const [filterBy, setFilterBy] = useState<FilterBy>({ id: 0, label: '', value: '', min: 0, max: 0 });

    const [sortFilter, setSortFilter] = useState<SortFilter>({});

    async function getTestsFromCategory({ tag }: { tag: string }) {
        /* const filterValue = filterBy.id !== 0 && { priceThreshold: `${filterBy.id !== 5 ? 'below' : 'above'}_${filterBy.value}` };
        const sortValue = sortBy.id !== 0 && { [sortBy.queryTxt]: sortBy.value }; */

        setLoading((prev) => (prev = true));
        const response = await getSortedTests({ page, limit: 10, ...(tag ? { tags: `["${tag}"]` } : {}), ...sortFilter });
        setLoading((prev) => (prev = false));

        if (response?.success && page > 1) {
            setTestCount((prev) => (prev = response.count));
            setTests((prev) => [...prev, ...response.data.results]);
        } else if (response.success && page < 2) {
            setTestCount((prev) => (prev = response.count));
            setTests((prev) => (prev = response.data.results));
        }
        setMinMax((prev) => ({ ...prev, min: 0, max: 0 }));
    }

    const contextValue = {
        tests,
        sortBy,
        filterBy,
        testCount,
        tag,
        page,
        minMax,
        loading,
        setLoading,
        setMinMax,
        setTests,
        getTestsFromCategory,
        setTag,
        setSortBy,
        setFilterBy,
        setPage,

        sortFilter,
        setSortFilter
    };

    return <LabTestCategoryContext.Provider value={contextValue}>{children}</LabTestCategoryContext.Provider>;
}

export const useLabTestCategoryContext = () => useContext(LabTestCategoryContext);

type SortFilter = {
    vendor?: string;
    tags?: string;
    price?: 'asc' | 'desc' | '';
    priceThreshold?: string;
    discount?: 'asc' | 'desc' | '';
    popular?: 'asc' | 'desc' | '';
    alphabet?: 'asc' | 'desc' | '';
};
