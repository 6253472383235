'use client';

import { useState, useEffect } from 'react';
import { getVendors } from '@/services/apis/lab-test/vendors';
import { useLabTestContext } from '@/lab-test/context/lab-test-context';
import { useLabCartContext } from '@/lab-test/context/lab-cart-context';
import { updateVendor } from '@/services/apis/lab-test/cart';
import { useRouter, useSearchParams } from 'next/navigation';
import { useLabTestCategoryContext } from '@/lab-test/context/lab-test-category-context';

import { RadioButtonChecked, RadioButtonUnchecked } from '@/lab-test/icons';
import { Open } from '@/services/types/lab-test';

import type { Vendor } from '@/services/types/lab-test/vendors';

import Image from 'next/image';
import LabToast from '@/lab-test/lab-toast';

import toast from 'react-hot-toast';
import styles from './index.module.scss';

export default function VendorsModal() {
    const router = useRouter();
    const searchParams = useSearchParams();

    const { selectedVendor, setSelectedVendor, defaultVendor, setDefaultVendor, setIsDrawerOpen, setOpen, open } = useLabTestContext();
    const { setLabCart } = useLabCartContext();
    const { sortFilter, setSortFilter } = useLabTestCategoryContext();

    const [vendors, setVendors] = useState<Vendor[]>([]);
    const [loading, setLoading] = useState(false);

    async function getVendorsList() {
        const response = await getVendors({ limit: 10, page: 1 });
        if (response?.success) {
            setVendors((prev) => (prev = response.data));
        }
    }

    async function confirmUpdateVendor() {
        setLoading((prev) => (prev = true));
        const response = await updateVendor(selectedVendor?.vendor?.id);
        setLoading((prev) => (prev = false));

        if (response?.success) {
            setLabCart((prev) => ({ ...prev, ...response.data }));
            setDefaultVendor((prev) => ({ ...prev, ...response.data.vendor }));
            setIsDrawerOpen((prev) => (prev = false));
            setOpen((prev) => (prev = Open.NONE));
            toast(() => <LabToast type="success" message={response?.message} />);
        } else {
            toast(() => <LabToast type="failed" message={response?.message} />);
        }
    }

    function applyVendorFilter() {
        if (searchParams.get('section') === 'lab') {
            router.replace(`/lab-test/tests?section=lab&lab=${selectedVendor?.vendor?.tag}&category=${selectedVendor?.vendor?.name?.en}`);
        } else if (searchParams.get('section') !== 'lab' && searchParams.get('tags')) {
            router.replace(
                `/lab-test/tests?section=${searchParams.get('section')}&tags=${searchParams.get('tags')}&lab=${selectedVendor?.vendor?.tag}&category=${selectedVendor?.vendor?.name?.en}`
            );
        } else {
            router.replace(
                `/lab-test/tests?section=${searchParams.get('section')}&lab=${selectedVendor?.vendor?.tag}&category=${selectedVendor?.vendor.name?.en}`
            );
        }
        setIsDrawerOpen((prev) => (prev = false));
        setOpen((prev) => (prev = Open.NONE));

        document.body.style.overflow = 'auto';
    }

    function handleVendorSelection(vendor: Vendor) {
        setSelectedVendor((prev) => ({
            ...prev,
            vendor: {
                id: vendor.id,
                image: { ...vendor.image },
                name: { ...vendor.name },
                isDefault: vendor.isDefault,
                status: vendor.status,
                tag: vendor.tag,
                totalTest: vendor.totalTest
            }
        }));
        if (sortFilter?.price || sortFilter?.discount || sortFilter?.priceThreshold) {
            setSortFilter((prev) => ({ ...prev, vendor: vendor.tag }));
        }
    }

    useEffect(() => {
        getVendorsList();
    }, []);

    function selectedVendorClass(vendor: Vendor) {
        if (selectedVendor?.vendor?.id === vendor.id || (!selectedVendor?.vendor?.id && defaultVendor.id === vendor.id)) {
            return 'lab-active';
        }

        if (!selectedVendor?.vendor?.id && searchParams.get('category') === vendor.name.en) {
            return 'lab-active';
        }

        if (defaultVendor.id === vendor.id && !searchParams.get('category') && !selectedVendor?.vendor?.id) {
            return 'lab-active';
        }

        return 'lab';
    }

    function renderCheckBox(vendor: Vendor) {
        if (selectedVendor?.vendor?.id === vendor.id || (!selectedVendor?.vendor?.id && defaultVendor.id === vendor.id)) {
            return <RadioButtonChecked fill="#0e7673" />;
        }

        if (!selectedVendor?.vendor?.id && searchParams.get('category') === vendor.name.en) {
            return <RadioButtonChecked fill="#0e7673" />;
        }

        if (defaultVendor.id === vendor.id && !searchParams.get('category')) {
            return <RadioButtonChecked fill="#0e7673" />;
        }

        return <RadioButtonUnchecked />;
    }

    return (
        <div className={styles['vendors-modal']} style={{ maxHeight: `calc(${window.innerHeight - 80}px)` }}>
            <div className={styles['title']}>Change Lab</div>
            <div className={styles['vendors']}>
                {vendors.map((vendor) => (
                    <div key={vendor.id} className={styles[selectedVendorClass(vendor)]} onClick={() => handleVendorSelection(vendor)}>
                        <div className={styles['icon']}>{renderCheckBox(vendor)}</div>
                        <div className={styles['lab-info']}>
                            <div className={styles['lab-image']}>
                                <Image src={vendor?.image?.app} alt={vendor?.name?.en} width={48} height={48} />
                            </div>
                            <div className={styles['lab-content']}>
                                <div className={styles['lab-name']}>{vendor?.name?.en}</div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <div className={styles['vendors-modal-action']}>
                <button
                    className={styles['confirm']}
                    onClick={open === Open.VENDORS_MODAL ? confirmUpdateVendor : applyVendorFilter}
                    disabled={loading}
                >
                    {open === Open.VENDORS_MODAL ? 'Confirm' : 'Apply'}
                </button>
            </div>
        </div>
    );
}
