import DiscountChip from '../discount-chip';

import styles from './index.module.scss';

export default function Pricing({
    discountPercent,
    discountPrice,
    regularPrice,
    reversed = false,
    variant = 'list',
    showDiscountChip = true
}: Pricing) {
    return variant === 'list' ? (
        !reversed ? (
            <div className={styles['pricing-list']}>
                <div className={styles['discount-price']}>৳{discountPrice}</div>
                {parseInt(regularPrice as string) > 0 && parseInt(discountPercent as string) > 0 && (
                    <div className={styles['regular-price']}>৳{regularPrice}</div>
                )}
                {parseInt(discountPercent as string) > 0 && <DiscountChip discountPercent={discountPercent} />}
            </div>
        ) : (
            <div className={styles['pricing-list']}>
                {parseInt(discountPercent as string) > 0 && <DiscountChip discountPercent={discountPercent} reverse={reversed} />}
                {parseInt(regularPrice as string) > 0 && parseInt(discountPercent as string) > 0 && (
                    <div className={styles['regular-price']}>৳{regularPrice}</div>
                )}
                <div className={styles['discount-price']}>৳{discountPrice}</div>
            </div>
        )
    ) : variant === 'grid' ? (
        !reversed ? (
            <div className={styles['pricing-grid']}>
                <div className={styles['discount-wrapper']}>
                    {parseInt(regularPrice as string) > 0 && parseInt(discountPercent as string) > 0 && (
                        <div className={styles['regular-price']}>৳{regularPrice}</div>
                    )}
                    {showDiscountChip && <DiscountChip discountPercent={discountPercent} />}
                </div>
                <div className={styles['discount-price']}>৳{discountPrice}</div>
            </div>
        ) : (
            <div className={styles['pricing-grid']}>
                <div className={styles['discount-wrapper-reversed']}>
                    {showDiscountChip && <DiscountChip discountPercent={discountPercent} reverse={reversed} />}
                    {parseInt(regularPrice as string) > 0 && parseInt(discountPercent as string) > 0 && (
                        <div className={styles['regular-price']}>৳{regularPrice}</div>
                    )}
                </div>
                <div className={styles['discount-price']}>৳{discountPrice}</div>
            </div>
        )
    ) : null;
}

interface Pricing {
    regularPrice: string | number;
    discountPrice: string | number;
    discountPercent: string | number;
    reversed?: boolean;
    variant?: 'list' | 'grid';
    showDiscountChip?: boolean;
}
